.drain-cleaning-container {
  max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 120px;
    margin-bottom: 150px;
}

.drain-cleaning-container h1 {
  color: #005792;
  text-align: center;
}

.back-button {
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #005792;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.back-button:hover {
  background-color: #003f5c;
}

.drain-cleaning-container p,
.drain-cleaning-container li {
  color: #333;
  line-height: 1.6;
}

.drain-cleaning-container ul {
  margin: 20px 0;
}

.drain-cleaning-container li {
  margin-bottom: 10px;
}

.drain-cleaning-container strong {
  color: #0077B6;
}