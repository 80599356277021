.contact-container {
  padding: 40px 20px;
  max-width: 800px;
  margin: auto;
}

.contact-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #444;
    text-align: center;
    background-color: #fafafaf0;
    border-radius: 86px;
}

.contact-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.contact-icons {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-form-container {
  flex: 2;
  padding: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 22px;
  font-family: philosopher, sans-serif;
}

.contact-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
   font-size: 22px;
  font-family: philosopher, sans-serif;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.status-message {
  color: #fff;
  background-color: #28a745; /* A green shade that's easy on the eyes */
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;

  /* Ensuring the message is noticeable but not intrusive */
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}