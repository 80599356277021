.service-container {
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto 40px;
  transition: box-shadow 0.3s ease-in-out;
}

.service-container:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.service-info-container {
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
  padding: 20px;
}

.service-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
  margin-top: 40px;
  font-family: philosopher, sans-serif;
}

.service-list {
  text-align: left;
  font-size: 1.6em;
  color: #060606;
  margin: 0 auto 50px;
  padding: 0;
  list-style-type: none;
}

.service-list li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.service-list li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #333;
  font-size: 1em;
  line-height: 1.2em;
}

.slick-slider {
  max-width: 100%;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.slick-slide img:hover {
  transform: scale(1);
}

.service-image {
  margin-top: 35px;
}