.sewer-repair-container {
 max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 120px;
    margin-bottom: 150px;
}

.sewer-repair-container h1 {
  font-size: 2.2em;
  margin: 40px 0 20px;
}

.sewer-repair-container img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 30px auto;
}

.sewer-repair-container p, .sewer-repair-container ul {
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 25px;
}

.sewer-repair-container ul li {
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}

.sewer-repair-container strong {
  color: #0077B6;
}

.sewer-repair-container h1 {
  color: #005792;
  text-align: center;
}