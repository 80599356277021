/* Container for the entire blog preview section */
:root {
  --blog-preview-bg-color: #666;
}

.blog-preview-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px;
  padding: 0;
}

.blog-preview {
  background-color: var(--blog-preview-bg-color);
  padding-bottom: 10px;
}

.blog-title {
  text-align: center;
  margin-bottom: 20px;
  color: #000;
  margin-top: 0px;
  padding: 24px;
  font-size: 2.5em;
  font-family: 'Philosopher', sans-serif; /* Ensure the font family is applied */
}

/* Individual blog post card styling */
.blog-post {
  background-color: #eeeaf8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.blog-post h3 {
  font-size: 1.8em;
  color: #333;
}

.blog-post p {
  font-size: 1.33em;
  color: #031cf5;
  margin: 10px 0 20px;
}

.blog-post .read-more {
  font-size: 1em;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.blog-post .read-more:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Hover effect for blog post cards */
.blog-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .blog-preview-container {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .blog-title {
    font-size: 2.2rem;
  }
}

@media (min-width: 1024px) {
  .blog-preview-container {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
  }
}