/* Root Variables */
:root {
  --nav-bg-color: #333;
  --nav-text-color: #fff;
  --nav-text-hover-color: #dd3232; /* Hover color */
  --nav-text-active-color: #4caf50; /* Active color */
  --nav-font-family: philosopher, sans-serif;
  --nav-font-size: 1.3em;
  --nav-height: 60px;
  --nav-mobile-font-size: 1em;
}


/* Navigation Bar Styling */
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--nav-height);
  background-color: var(--nav-bg-color);
  padding: 0 20px;
  z-index: 100;
}

/* Links Styling */
nav a {
  color: var(--nav-text-color);
  text-decoration: none;
  font-family: var(--nav-font-family);
  font-size: var(--nav-font-size);
  margin: 0 15px;
  transition: color 0.3s ease; /* Smooth color transition */
}

/* Hover State */
nav a:hover,
.menu-items button:hover {
  color: var(--nav-text-hover-color); /* Hover color */
}

/* Active State */
nav a.active,
.menu-items button.active {
  color: var(--nav-text-active-color); /* Active color */
}

/* Menu Items */
.menu-items {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.menu-items button {
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease; /* Smooth color transition */
}

.nav-button {
  color: var(--nav-text-color);
  font-family: var(--nav-font-family);
  font-size: var(--nav-font-size);
  margin: 0 15px;
  transition: color 0.3s ease; /* Smooth color transition */
}

/* Button Hover and Active State */
.menu-items button:hover {
  color: var(--nav-text-hover-color); /* Button hover color */
}

.menu-items button.active {
  color: var(--nav-text-active-color); /* Button active color */
}

/* Call Now Section */
.call-now {
  display: none;
  padding: inherit;
}

.call-now-link-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #39ac6b;
  padding: 10px 20px;
  border-radius: 43px;
  transition: background-color 0.3s ease;

}

.call-now-link-navbar:hover {
  background-color: var(--nav-text-hover-color);
}

/* Burger Menu */
.burger {
  display: none;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 1024px) {
  nav {
    justify-content: space-between;
    padding: 10px;
  }

  .menu {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--nav-bg-color);
    position: absolute;
    top: var(--nav-height);
    left: 0;
  }

  .menu.open {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .home-icon {
    margin-right: auto;
  }

  .menu-items{
    display: flex;
    flex-direction: row;
    font-size: var(--nav-mobile-font-size);
  }

  .menu-items a {
    margin: 0 5px;
    font-size: 0.7em;
  }

  .nav-button {
    font-size: 0.5em;
  }

  .call-now {
    display: none;
  }
}

/* Media Queries for larger screens */
@media (min-width: 1024px) {
  .call-now {
    display: block;
  }
}

/* Additional Media Queries for smaller screens */
@media ( max-width: 768px) {
  nav a,
  .menu-items button {
    font-size: 0.7em;
    margin: 0 10px;
  }
}

@media (max-width: 480px) {
  nav a,
  .menu-items button {
    font-size: 0.7em;
    margin: 0 5px;
  }
}
