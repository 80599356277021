/* Define the custom font */
@font-face {
  font-family: 'Philosopher';
  src: url('../../public/Philosopher/Philosopher-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: philosopher, sans-serif;
  background-color: rgb(223, 238, 245);
  /* background-repeat: no-repeat; */
  /* background-image: url('../images/KloakLogo.png'); */
  background-size: cover;
}

/* Move the background image down */
.about-container.down {
  background-position: center 20%; /* Adjust the percentage to move the image down */
}

/* Move the background image up */
.about-container.up {
  background-position: center -20%; /* Adjust the percentage to move the image up */
}

.about-info-container {
  margin-top: 35px;
}

.about-text, .commitment-text {
  border-radius: 45px;
  padding-bottom: 4px;
}

.about-title,
.commitment-title {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 2.5em;
  padding-top: 30px;
}


.about-img {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 25px;
}

.about-paragraph,
.commitment-paragraph {
  color: #666;
  line-height: 1.6;
  text-align: justify;
  font-size: 26px;
  margin: 70px;
}

/* Responsive design adjustments */

@media (min-width: 768px) {
  .about-info-container,
  .commitment-container {
    flex-direction: grid;
    justify-content: space-between;
  }

  .about-image,
  .commitment-image {
    margin: 0 20px 0 0;
  }

  .about-text,
  .commitment-text {
    flex: 1;
    background-color: gainsboro;
  }
}

.call-now-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff ;
  background-color: #39ac6b;
  padding: 10px 20px;
  border-radius: 43px;
  transition: background-color 0.3s ease;
  width: 295px;
  height: 55px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  font-size: 24px;
}

.call-now-link:hover {
  background-color: rgb(233, 51, 35); /* Darker blue on hover */
}

.call-now-link svg {
  margin-right: 8px; /* Adds space between the icon and text */
}

@media (max-width: 768px) {
  .about-title,
  .commitment-title {
    font-size: 1.2em;
  }
  .call-now-link {
    margin-left: auto; /* Pushes the call button to the right */
  }

  .about-paragraph,
  .commitment-paragraph {
    font-size: 18px;
    text-align: justify;
    margin: 15px;
    padding: 0;
    line-height: 1.5;
  }

  .about-text,
  .commitment-text {
    flex: 1 1;
    background-color: gainsboro;
  }
}
