.regular-sewer-maintenance-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 120px;
  margin-bottom: 150px;
}

.regular-sewer-maintenance-container h1 {
  color: #005792;
  text-align: center;
}

.regular-sewer-maintenance-container p,
.regular-sewer-maintenance-container li {
  color: #333;
  line-height: 1.6;
}

.regular-sewer-maintenance-container ul {
  margin: 20px 0;
}

.regular-sewer-maintenance-container li {
  margin-bottom: 10px;
}

.regular-sewer-maintenance-container strong {
  color: #0077B6;
}