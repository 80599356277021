.workers-list {
  padding: 20px;
  background-color: #f4f4f9;
  text-align: center;
}

.workers-list-title {
  color: #000;
  margin: 84px 36px;
  font-family: philosopher, sans-serif;
  font-size: 40px;
}

.top-worker-container {
  display: flex;
  justify-content: center;
  /* margin-bottom: px; */
}

.workers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 120px;
  margin: 50px 50px;
}

.worker-name {
  font-family: philosopher, sans-serif;
  font-size: 2em;
  margin: 10px 0;
  color: #333;
}

.worker-role {
  font-size: 1.5em;
  color: #777;
}

.worker-image {
  border-radius: 50%;
  width: 280px;
  height: 300px;
  object-fit: cover;
  margin-bottom: 10px;
}

.workers-list hr.separation-line {
  border: 0;
  border-top: 3px solid #0b0b0b;
  margin: 1px auto; /* Space between the lines */
  width: 80%; /* Make the lines a bit shorter */
}

@media (max-width: 768px) {
  .workers-container {
    gap: 20px;
  }

  .worker-image {
    width: 100px;
    height: 100px;
  }
}